import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ShadowText } from 'components/styled/typography'
import { SecondaryButton } from 'components/styled/button'
import Layout from 'layouts/main'
import { SCREEN } from 'styles/screens'
import { formatDate, formatName, getImageUrl } from '../Utils'
import axios from 'axios'
import { config } from '../config'
import { Link } from 'gatsby'
import { getTranslation } from 'data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  img {
    object-fit: cover;
  }
  .hr {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid #bababa;
  }
  .title {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 500;
  }

  .lh-23px {
    line-height: 23px;
  }

  .lh-34px {
    line-height: 34px;
  }

  .text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
  }

  img.author {
    margin-right: 1rem;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    object-fit: cover;
  }
  .py-3rem {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-1rem {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .mb-1rem {
    margin-bottom: 1rem;
  }

  .mh-400 {
    max-height: 400px;
  }

  .mh-170 {
    max-height: 170px;
  }

  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pl-0 {
    padding-left: 0;
  }

  h3 {
    margin-bottom: 8px !important;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    .my-mobile-10px {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .scale-mobile {
      transform: scale(1.065);
    }

    .column-mobile-reverse {
      display: flex;
      flex-direction: column-reverse;
    }

    .title {
      font-size: 14px;
    }
    .px-0.px-mobile-0,
    .px-mobile-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    padding: 16px;
    img.author {
      margin-right: 0.5rem;
      height: 35px;
      width: 35px;
    }

    .py-3rem {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    .recent-posts {
      h3.fs-30px {
        font-size: 14px !important;
      }

      p {
        font-size: 13px !important;
      }

      img:not(.author) {
        height: 60px;
        width: 60px;
      }
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1520px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 0 0 100%;

  p {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 27px;
    letter-spacing: -0.1px;
  }

  p:first-child {
    margin-top: 80px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  &.row-reverse {
    flex-direction: row-reverse;
  }
`

const Column = styled.div`
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  max-width: 100%;
  flex: 1 0 0%;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    &.px-0 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
`

const ColumnEight = styled.div`
  flex-direction: column;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
  padding-left: 15px;
  padding-right: 15px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    &.not-wrapped {
      max-width: 100%;
      flex: 1 0 0%;
    }

    flex: 0 0 100%;
    max-width: 100%;
  }
`

const ColumnFour = styled.div`
  flex-direction: column;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
  padding-left: 15px;
  padding-right: 15px;

  h3 {
    margin: 1rem 0;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    &.not-wrapped {
      flex: 0 0 auto !important;
      max-width: auto !important;
    }

    flex: 0 0 100%;
    max-width: 100%;
  }
`

const ColAuto = styled.div`
  flex: 0 0 auto;
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
`

const BlogPage = ({ pageContext }) => {

  const [recent, setRecent] = useState([])
  const [featured, setFeatured] = useState([])
  const [popular, setPopular] = useState([])

  useEffect(async () => {
    loadMore()
    let f = (await axios.get(`${config.API_BASE_URL}/posts?featured=true&enabled=true`)).data
    setFeatured(f)
    let p = (await axios.get(`${config.API_BASE_URL}/posts?enabled=true&_sort=views:DESC&_limit=5`)).data
    setPopular(p)
  }, [])

  const loadMore = async () => {
    let recentPosts = (await axios.get(`${config.API_BASE_URL}/posts?featured=false&enabled=true&_sort=sortPosition:DESC&_limit=5&_start=${recent.length}`)).data
    setRecent([...recent, ...recentPosts])
  }

  return (
    <Layout title={`BuildUp: Blog`} hideTitlePrefix={true}>
      <div className='page'>
        <Container>
          <FixedContainer>
            <DescriptionContainer>
              <ShadowText highlight>
                <h3>{getTranslation("BuildUp-Blog", "", true)}</h3>
              </ShadowText>

              {featured.map(post => {
                return <Row key={`post-${post.id}`}>
                  <ColumnEight className='pl-0 px-mobile-0'>
                    <img
                      src={getImageUrl(post.image)}
                      className='w-100 img-fluid mh-400 scale-mobile' alt='' />
                  </ColumnEight>
                  <ColumnFour className='pr-0 px-mobile-0'>
                    <div className='column-mobile-reverse'>
                      <div className='d-flex align-items-center px-0'>
                        <img
                          src={getImageUrl(post.author.image)}
                          className='author' alt='' />
                        <div className='my-mobile-10px'>
                          <h6 className='title'>{formatName(post.author)}</h6>
                          <p className='text text-muted'>{formatDate(post.date)} - {post.duration} min read</p>
                        </div>
                      </div>
                      <h3 className='fs-30px lh-34px'>{post.title}</h3>
                    </div>

                    <p className='card-text lh-23px fs-18px'>{post.excerpt}...</p>
                    <SecondaryButton onClick={() => {
                      window.location.href = `/blog/${post.key}`;
                    }} className='mt-15px'>Read Full</SecondaryButton>
                  </ColumnFour>
                </Row>
              })}
              <Row className='mt-100px'>
                <ColumnEight className='recent-posts pl-0 px-mobile-0'>
                  <h6 className='fs-18px mb-1rem mt-0'>{getTranslation("RECENT-POSTS", "", true)}</h6>
                  {recent.map(post => {
                    return <Row key={`post-${post.id}`} className='align-items-center border-top py-3rem'>
                      <ColumnEight className='not-wrapped pl-0'>
                        <div className='d-flex align-items-center px-0'>
                          <img
                            src={getImageUrl(post.author.image)}
                            className='author' alt='' />
                          <div>
                            <h6 className='title'>{formatName(post.author)}</h6>
                          </div>
                        </div>
                        <a href={`/blog/${post.key}`} className="text-dark text-decoration-none">
                          <h3 className='fs-30px lh-34px mt-1'>{post.title}</h3>
                        </a>

                        <p className='card-text lh-23px fs-18px'>
                          {post.excerpt}
                        </p>
                        <p className='text text-muted'>{formatDate(post.date)} - {post.duration} min read</p>
                      </ColumnEight>
                      <ColumnFour className='not-wrapped pr-0'>
                        <a href={`/blog/${post.key}`}>
                          <img
                            src={getImageUrl(post.image)}
                            className='w-100 mh-170' alt='' />
                        </a>
                      </ColumnFour>
                    </Row>
                  })}

                  {recent.length % 5 === 0 &&
                    <Row>
                      <Column className='px-0  mb-100px px-mobile-0'>
                        <SecondaryButton onClick={loadMore}>{getTranslation("view-more", "", true)}</SecondaryButton>
                      </Column>
                    </Row>
                  }
                </ColumnEight>


                <ColumnFour className='px-0 pr-0'>
                  <h6 className='fs-18px mb-1rem mt-0'>{getTranslation("MOST-POPULAR-POSTS", "", true)}</h6>
                  <hr className="hr" />
                  {popular.map(post => {
                    return <Row key={`post-${post.id}`} className='row-reverse py-1rem'>
                      <Column className='pr-0'>
                        <a href={`/blog/${post.key}`} className="text-dark text-decoration-none">
                          <h3 className='fs-16px mt-0 mb-0 fs-16px'>
                            {post.title}
                          </h3>
                        </a>
                        <p className='text text-muted'>{formatDate(post.date)} - {post.duration} min read</p>
                      </Column>
                      <ColAuto className='pl-0'>
                        <a href={`/blog/${post.key}`}>
                          <img
                            src={getImageUrl(post.image)}
                            className='w-85px h-65px' alt='' />
                        </a>
                      </ColAuto>
                    </Row>
                  })}
                </ColumnFour>
              </Row>
            </DescriptionContainer>
          </FixedContainer>
        </Container>
      </div>
    </Layout>
  )
}

export default BlogPage
